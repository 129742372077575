import { isAfter, isBefore, isValid } from "date-fns";
import { Asset } from "store/models/Asset";
import { ICurrency } from "types/Currency";
import * as Yup from "yup";
import { endDateIsValid, isMMDDYYYY, isPhoneNumberValid, outDateIsValid, startDateIsValid } from "./util";

export const userValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  countries: Yup.array(Yup.string())
    .required("Required")
    .test((countries) => Boolean(countries?.length)),
  phone: Yup.string()
    .notRequired()
    .test((phoneNumber) =>
      phoneNumber ? isPhoneNumberValid(phoneNumber).valid : true
    ),
});


export const yupAddress = {
  address: Yup.string(),
  latLng: Yup.object({
    lat: Yup.string().required(),
    lng: Yup.string().required()
  })
}

export const validateCurrency = {
  amount: Yup.number().min(0),
  currency: Yup.string().required("Required"),
}

export const assetValidationSchema = Yup.object<
  Partial<Record<keyof Asset, Yup.AnySchema>>
>({
  assetNumber: Yup.string().required("Required"),
  branch: Yup.string().required("Required"),
  serialNumber: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  yard: Yup.string().required("Required"),
  condition: Yup.string().notRequired().nullable(),
  inServiceDate: Yup.string().nullable(),
  capitalCost: Yup.object().shape(validateCurrency),
  accumulatedImpairment: Yup.object().shape(validateCurrency),
  usefulLife: Yup.number().positive().test(function (){
    return typeof this.parent.usefulLife === "number"
  }),
  residualValue: Yup.number().positive(),
  yearOfManufacture: Yup.string().test("Format needs to be YYYY", function (value) {
    return value ? /^\d{4}$/.test(value) : true;
  }),
});

export const contractValidationSchema = Yup.object().shape({
  assetDeliverables: Yup.array().required("At Least One Asset Required"),
  startDate: Yup.string().test(function () {
    return startDateIsValid(
      this.parent.startDate,
      this.parent.outDate,
      this.parent.endDate,
      this.parent.evergreen,
      this.parent.contractType === "Sale"
    );
  }),
  outDate: Yup.string().test(function () {
    return (
      isValid(new Date(this.parent.outDate)) &&
      outDateIsValid(new Date(this.parent.outDate), this.parent.startDate)
    );
  }),
  endDate: Yup.string()
    .nullable()
    .notRequired()
    .test(function () {
      return (
        this.parent.evergreen ||
        Boolean(
          !!this.parent.endDate &&
            isValid(new Date(this.parent.endDate)) &&
            endDateIsValid(
              this.parent.endDate,
              this.parent.startDate,
              this.parent.evergreen,
              this.parent.contractType === "Sale"
            )
        )
      );
    }),
  client: Yup.string().required("Required"),
  billTo: Yup.object(yupAddress).test(latLngRequired),
  shipTo: Yup.object(yupAddress).test(latLngRequired),
  securityDeposit: Yup.object().test(function validateSecurityDesposit(){
    return !this.parent.securityDeposit.received || this.parent.securityDeposit.amount.amount > 0; 
  })
});

const accessoryValidationSchemaContents = {
  asset: Yup.string().nullable(),
  unitOfMeasure: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  quantity: Yup.number().min(1).test(function (){
    return typeof this.parent.quantity === "number";
  }),
  rate: Yup.object().shape(validateCurrency),
  customBillingStart: Yup.date().nullable().test(function () {
    return isValid(this.parent.customBillingEnd) &&
      isValid(this.parent.customBillingStart)
      ? isBefore(this.parent.customBillingStart, this.parent.customBillingEnd)
      : true;
  }),
  customBillingEnd: Yup.date().nullable().test(function () {
    return isValid(this.parent.customBillingStart) &&
      isValid(this.parent.customBillingEnd)
      ? isAfter(this.parent.customBillingEnd, this.parent.customBillingStart)
      : true;
  }),
}

export const accessoryValidationSchema = Yup.object().shape({...accessoryValidationSchemaContents});

export const accessoryValidationSchemaBranchRequired = Yup.object().shape({...accessoryValidationSchemaContents, branch: Yup.string().required("Branch Required")});

export function latLngRequired(value: any) {
  return value.latLng.lat && value.latLng.lng
}

export const clientValidationSchema = Yup.object().shape({
  legalName: Yup.string().required("Required"),
  companyName: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  fax: Yup.string().test((phoneNumber) =>
    phoneNumber ? isPhoneNumberValid(phoneNumber).valid : true
  ),
  phoneNumber: Yup.string()
    .required("Required")
    .test((phoneNumber) =>
      phoneNumber ? isPhoneNumberValid(phoneNumber).valid : true
    ),
});

const assetValidation = Yup.object().shape({
  _id: Yup.string().required("Required"),
  serialNumber: Yup.string().required("Required"),
  assetNumber: Yup.string().required("Required"),
})

const assignedToValidation = Yup.object().shape({
  _id: Yup.string(),
  firstName: Yup.string(),
  lastName: Yup.string(),
}).notRequired().nullable()

const createdByValidation = Yup.object().shape({
  _id: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
})

export const workOrderValidationSchema = Yup.object().shape({
  asset: assetValidation,
  dueDate: Yup.string().required("Required"),
  completedDate: Yup.string().nullable().notRequired(),
  title: Yup.string().required("Required"),
  priority: Yup.string().required("Required"),
  description: Yup.string().optional(),
  assignedTo: assignedToValidation,
  status: Yup.string()
    .required("Required"),
  createdOn: Yup.string().required("Required"),
  createdBy: createdByValidation,
  category: Yup.string().required("Required"),
  subcategory: Yup.string().required("Required"),
  estimatedHours: Yup.number().required("Required").test((value) => typeof value === "number" && value >= 0),
  estimatedMaterials: Yup.object()
    .shape(validateCurrency)
    .required("Required")
    .test((currency: ICurrency) => currency.amount >= 0),
  actualHoursToComplete: Yup.number().notRequired().nullable().min(0),
  actualMaterialsToComplete: Yup.object().shape(validateCurrency).nullable(),
  tasks: Yup.array(
    Yup.object({
      description: Yup.string(),
      complete: Yup.boolean(),
      completedBy: Yup.string().nullable(),
      completedDate: Yup.string().nullable(),
    })
  )
    .optional(),
  purchaseOrder: Yup.object()
    .shape({
      poNumber: Yup.string(),
      amount: Yup.object().shape(validateCurrency).required(),
    })
    .test((value): Yup.ValidationError | boolean => {
      if (value.amount.amount > 0 && !value?.poNumber) {
        return new Yup.ValidationError("Must have a PO # when a PO Amount is present", value.poNumber, "purchaseOrder.poNumber")
      }
      return true
    }),
});

export const inspectionWorkOrderValidation = Yup.object().shape({
  asset: assetValidation,
  dueDate: Yup.string().required("Required"),
  priority: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  createdOn: Yup.string().required("Required"),
  createdBy: createdByValidation,
  // assignedTo: Yup.string().notRequired().nullable(),
  purchaseOrder: Yup.object()
    .shape({
      poNumber: Yup.string(),
      amount: Yup.object().shape(validateCurrency).required(),
    })
    .test((value): Yup.ValidationError | boolean => {
      if (value.amount.amount > 0 && !value?.poNumber) {
        return new Yup.ValidationError("Must have a PO # when a PO Amount is present", value.poNumber, "purchaseOrder.poNumber")
      }
      return true
    }),
})

export const checkListItemValidationSchema = Yup.object().shape({
  dueDate: Yup.string().when("result", {
    is: "Fail",
    then: Yup.string().required("Due date is required")
  }),
  title: Yup.string().when("result", {
    is: "Fail",
    then: Yup.string().required("Title is required")
  }),
  priority: Yup.string().when("result", {
    is: "Fail",
    then: Yup.string().required("Priority is required")
  }),
  description: Yup.string().optional(),
  workOrderCategory: Yup.string().when("result", {
    is: "Fail",
    then: Yup.string().required("Category is required")
  }),
  workOrderSubcategory: Yup.string().when("result", {
    is: "Fail",
    then: Yup.string().required("Subcategory is required")
  }),
  estimatedHours: Yup.number().required("Required").test((value) => typeof value === "number" && value >= 0),
  estimatedMaterials: Yup.object()
    .shape(validateCurrency)
    .required("Required")
    .test((currency: ICurrency) => currency.amount >= 0),
  tasks: Yup.array(
    Yup.object({
      description: Yup.string(),
      complete: Yup.boolean(),
      completedBy: Yup.string().nullable(),
      completedDate: Yup.string().nullable(),
    })
  ).optional(),
  quantity: Yup.number().notRequired().nullable()
})

export const MovementValidationSchema = Yup.object().shape({
  asset: Yup.string().test(function () {
    return !this.parent.nonAsset && !this.parent.accessory ? this.parent.asset : true
  }).nullable(),
  nonAsset: Yup.object().shape({
    description: Yup.string().test(function () {
      return !this.parent.asset && !this.parent.accessory ? this.parent.description : true
    }),
    branch: Yup.string().test(function () {
      return !this.parent.asset ? this.parent.branch : true
    }).nullable(),
  }).nullable(),
  accessory: Yup.string().test(function () {
    return !this.parent.nonAsset && !this.parent.asset ? this.parent.accessory : true
  }).nullable(),
  dateOfMove: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  billOfLading: Yup.string().nullable(),
  transportCompanyName: Yup.string(),
  createdBy: Yup.string().required("Required"),
});

export const billOfLadingValidationSchema = Yup.object({
  billOfLadingNumber: Yup.string().required("Required"),
  assetArticles: Yup.array().of(
    Yup.object({
      asset: Yup.string().required("Required"),
      attributes: Yup.object({
        width: Yup.number().positive().required("Required"),
        length: Yup.number().positive().required("Required"),
        height: Yup.number(),
        weight: Yup.number(),
        amount: Yup.object({
          amount: Yup.number().required("Required"),
          currency: Yup.string().required("Required")
        }).required("Required"),
        hazardous: Yup.boolean().required("Required"),
        notes: Yup.string().optional(),
        _id: Yup.string()
      }).required("Required"),
      _id: Yup.string()
    }).required("Required")
  ).required("Required"),
  additionalItems: Yup.array(
    Yup.object().shape({
        description: Yup.string().required("Required"),
        quantity: Yup.number().required("Required"),
        width: Yup.number(),
        length: Yup.number(),
        height: Yup.number(),
        weight: Yup.number(),
        amount: Yup.object({
          amount: Yup.number().required("Required"),
          currency: Yup.string().required("Required")
        }).required("Required"),
        hazardous: Yup.boolean().required("Required"),
        notes: Yup.string().optional(),
    })
  ),
  deliveryDate: Yup.string()
    .notRequired()
    .nullable()
    .test(function () {
      return this.parent.deliveryDate ? isMMDDYYYY(this.parent.deliveryDate) : true;
    }),
  acknowledgementAtOriginConsignorDate: Yup.string()
    .notRequired()
    .nullable()
    .test(function () {
      return this.parent.acknowledgementAtOriginConsignorDate ? isMMDDYYYY(this.parent.acknowledgementAtOriginConsignorDate) : true;
    }),
  acknowledgementAtDestinationConsigneeDate: Yup.string()
    .notRequired()
    .nullable()
    .test(function () {
      return this.parent.acknowledgementAtDestinationConsigneeDate ? isMMDDYYYY(this.parent.acknowledgementAtDestinationConsigneeDate) : true;
    }),
  acknowledgementAtOriginCarrierDate: Yup.string()
    .notRequired()
    .nullable()
    .test(function () {
      return this.parent.acknowledgementAtOriginCarrierDate ? isMMDDYYYY(this.parent.acknowledgementAtOriginCarrierDate) : true;
    }),
  acknowledgementAtDestinationCarrierDate: Yup.string()
    .notRequired()
    .nullable()
    .test(function () {
      return this.parent.acknowledgementAtDestinationCarrierDate ? isMMDDYYYY(this.parent.acknowledgementAtDestinationCarrierDate) : true;
    }),
  scheduledPickupTimeAtOrigin: Yup.date().notRequired().nullable().test(function() {
    return this.parent.consignorDepartureTime ? isValid(this.parent.consignorDepartureTime) : true;
  }),
  scheduledDeliveryTimeAtDestination: Yup.date().notRequired().nullable().test(function() {
    return this.parent.consignorDepartureTime ? isValid(this.parent.consignorDepartureTime) : true;
  }),
  consignorArrivalTime: Yup.date().notRequired().nullable().test(function() {
    return this.parent.consignorArrivalTime ? isValid(this.parent.consignorArrivalTime) : true;
  }),
  consignorDepartureTime: Yup.date().notRequired().nullable().test(function() {
    return this.parent.consignorDepartureTime ? isValid(this.parent.consignorDepartureTime) : true;
  }),
  consigneeSiteArrivalTime: Yup.date().notRequired().nullable().test(function() {
    return this.parent.consigneeSiteArrivalTime ? isValid(this.parent.consigneeSiteArrivalTime) : true;
  }),
  consigneeSiteDepartureTime: Yup.date().notRequired().nullable().test(function() {
    return this.parent.consigneeSiteDepartureTime ? isValid(this.parent.consigneeSiteDepartureTime) : true;
  }),
  declaredValuation: Yup.number()
    .notRequired()
    .nullable()
    .test(function () {
      return this.parent.declaredValuation
        ? typeof this.parent.declaredValuation === "number"
        : true;
    }),
  consignorLoadingTimeTotalMinutes: Yup.number()
    .notRequired()
    .nullable()
    .test(function () {
      return this.parent.consignorLoadingTimeTotalMinutes
        ? typeof this.parent.consignorLoadingTimeTotalMinutes === "number"
        : true;
    }),
  consigneeUnloadingTimeTotalMinutes: Yup.number()
    .notRequired()
    .nullable()
    .test(function () {
      return this.parent.consigneeUnloadingTimeTotalMinutes
        ? typeof this.parent.consigneeUnloadingTimeTotalMinutes === "number"
        : true;
    }),
  consigneeOtherTimeAtOriginOrDestinationTotalMinutes1: Yup.number()
    .notRequired()
    .nullable()
    .test(function () {
      return this.parent.consigneeOtherTimeAtOriginOrDestinationTotalMinutes1
        ? typeof this.parent
            .consigneeOtherTimeAtOriginOrDestinationTotalMinutes1 === "number"
        : true;
    }),
  consigneeOtherTimeAtOriginOrDestinationTotalMinutes2: Yup.number()
    .notRequired()
    .nullable()
    .test(function () {
      return this.parent.consigneeOtherTimeAtOriginOrDestinationTotalMinutes2
        ? typeof this.parent
            .consigneeOtherTimeAtOriginOrDestinationTotalMinutes2 === "number"
        : true;
    }),
  consigneeOtherTimeAtOriginOrDestinationTotalMinutes3: Yup.number()
    .notRequired()
    .nullable()
    .test(function () {
      return this.parent.consigneeOtherTimeAtOriginOrDestinationTotalMinutes3
        ? typeof this.parent
            .consigneeOtherTimeAtOriginOrDestinationTotalMinutes3 === "number"
        : true;
    }),
});


export const contactValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().optional(),
  email: Yup.string().email().notRequired().nullable(),
  primaryPhone: Yup.string()
    .required()
    .test((phoneNumber) =>
      phoneNumber ? isPhoneNumberValid(phoneNumber).valid : true
    ),
  altPhone: Yup.string()
    .nullable()
    .test((phoneNumber) =>
      phoneNumber ? isPhoneNumberValid(phoneNumber).valid : true
    ),
  currentClient: Yup.string().required("Required"),
  companyRole: Yup.string().required("Required")
});


