import React, { FC, ReactNode, useState } from "react";
import { Box, Grid, Typography, Card } from "@mui/material";
import ImageCarousel from "components/image/ImageCarousel";
import { setGlobalMessage, simpleGlobalMessage } from "store/slices/systemSlice";
import { Photo } from "store/models/Photo";
import usePermissions, {
  FeatureType,
  ActionType,
} from "hooks/usePermissions";
import { useAppDispatch } from 'store/store';
import CopyClipboard from "components/CopyClipboard";
import AttachmentList from "components/AttachmentList";
import { fCurrency, fCurrencyFloat } from "utils/formatNumber";
import { calcNetBookValue, calcAccumulatedAmortization, calcMonthlyDepreciation, assetIsPastUsefulLife, calcResidualValue, Asset } from "store/models/Asset";
import { dateFromMMDDYYYY, feetToFeetInches } from "utils/util";
import { useAddAssetPhotoMutation, useDeleteAssetPhotoMutation } from "store/services/asset";
import { format } from "date-fns";
import { useGetBranchesQuery } from "store/services/branches";
import { byIds } from "store/sliceUtils";
import { useGetYardsQuery } from "store/services/yard";

const AssetRow: FC<{children?: ReactNode}> = ({ children }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={(theme) => ({ py: 0.5, fontSize: theme.typography.subtitle2 })}
    >
      {children}
    </Box>
  );
};

function AssetField({ children }: { children: ReactNode }) {
  return (
    <Typography
      variant="subtitle2"
      sx={{ color: "text.secondary", alignSelf: "center" }}
      gutterBottom
    >
      {children}
    </Typography>
  );
}

interface Props {
  photos: Photo[];
  asset: Asset;
}

const AssetDetails: React.FC<Props> = ({photos, asset}) => {
  const { data: branches = [] } = useGetBranchesQuery();
  const branchesHash = byIds(branches);
  const { data: yards = [] } = useGetYardsQuery();
  const yardsHash = byIds(yards);
  const dispatch = useAppDispatch();
  const [addAssetPhoto] = useAddAssetPhotoMutation();
  const [deleteAssetPhoto, {isLoading: isDeletingPhoto}] = useDeleteAssetPhotoMutation();
  const checkAssetPermissions = usePermissions(FeatureType.ASSET);
  const userCanEditAssets = checkAssetPermissions(ActionType.UPDATE);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleImageUpload = async (form: FormData) =>  {
    if(isSubmitting) return;
    addAssetPhoto({ assetId: asset._id, file: form }).unwrap().catch((error) => {
      dispatch(simpleGlobalMessage(error.message));

    })
  }


  const deleteImage = async ({ _id: photoId }: Photo) => {
    if (isDeletingPhoto) return;
    deleteAssetPhoto({ assetId: asset._id, photoId }).unwrap().catch((error) => {
      dispatch(setGlobalMessage({messageText: error.data?.message || error.message || "An unknown error occurred", severity: 'error', show: true}));
    })
  };

  const monthlyDepreciation = assetIsPastUsefulLife(asset) ? 0 : calcMonthlyDepreciation(asset);

  return (
    <>

        <ImageCarousel
          handleImageUpload={handleImageUpload}
          deleteImage={deleteImage}
          images={photos}
          permission={userCanEditAssets || isDeletingPhoto}
          allowMultiple
        />


      <Card sx={{ p: 2.5, mb: 2 }}>
        <Box display="flex" flexDirection="column" gap="3">
          <AssetRow>
            <AssetField>Asset #:</AssetField>
            {asset?.assetNumber}
          </AssetRow>
          <AssetRow>
            <AssetField>Serial #:</AssetField>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CopyClipboard
                value={asset.serialNumber}
                tooltipText="Copy Serial #"
                successText={`Copied asset Serial #: ${asset.serialNumber}`}
              />
              {asset?.serialNumber}
            </Box>
          </AssetRow>
          <AssetRow>
            <AssetField>Branch:</AssetField>
            {branchesHash[asset.branch]?.name}
          </AssetRow>
          <AssetRow>
            <AssetField>Yard:</AssetField>
            {yardsHash[asset.yard]?.name}
          </AssetRow>
          <AssetRow>
            <AssetField>Yard Position:</AssetField>
            {asset.yardPosition}
          </AssetRow>
          <AssetRow>
            <AssetField>Category:</AssetField>
            {asset?.category}
          </AssetRow>
          <AssetRow>
            <AssetField>Sub Category:</AssetField>
            {asset?.subCategory}
          </AssetRow>
          <AssetRow>
            <AssetField>Condition:</AssetField>
            {asset?.condition}
          </AssetRow>
          <AssetRow>
            <AssetField>Branding:</AssetField>
            {asset?.branding}
          </AssetRow>
          <AssetRow>
            <AssetField>Manufacturer:</AssetField>
            {asset?.manufacturer}
          </AssetRow>
          <AssetRow>
            <AssetField>Hours:</AssetField>
            {asset?.hrs}
          </AssetRow>
          <Grid container item xs={12}>
            <Typography sx={{ color: "text.secondary", mr: 2 }} gutterBottom>
              Notes:
            </Typography>
            {asset?.notes}
          </Grid>
          <AssetRow>
            <AssetField>Year Of Manufacture</AssetField>
            {asset.yearOfManufacture}
          </AssetRow>
          <AssetRow>
            <AssetField>Building Code:</AssetField>
            {asset?.buildingCode}
          </AssetRow>
          <AssetRow>
            <AssetField>A/C Type:</AssetField>
            {asset?.acType}
          </AssetRow>
          <AssetRow>
            <AssetField>Heating Type</AssetField>
            {asset.heatingType}
          </AssetRow>
          <AssetRow>
            <AssetField>Cladding:</AssetField>
            {asset?.cladding}
          </AssetRow>
          <AssetRow>
            <AssetField>Electrical:</AssetField>
            {asset?.electrical}
          </AssetRow>
          <AssetRow>
            <AssetField>Fuel Type:</AssetField>
            {asset?.fuelType}
          </AssetRow>
          <AssetRow>
            <AssetField>Floor Style:</AssetField>
            {asset?.floorStyle}
          </AssetRow>
          <AssetRow>
            <AssetField>Size Code:</AssetField>
            {asset?.sizeCode}
          </AssetRow>
          <AssetRow>
            <AssetField>Height:</AssetField>
            {feetToFeetInches(asset?.height)}
          </AssetRow>
          <AssetRow>
            <AssetField>Structural Limit:</AssetField>
            {asset?.structuralLimit}
          </AssetRow>
          <AssetRow>
            <AssetField>Frame</AssetField>
            {asset.frame}
          </AssetRow>
          <AssetRow>
            <AssetField>Colour</AssetField>
            {asset.colour}
          </AssetRow>
          <AssetRow>
            <AssetField># of Washrooms</AssetField>
            {asset.wash}
          </AssetRow>
          <AssetRow>
            <AssetField>Partitions:</AssetField>
            {asset?.partitions}
          </AssetRow>
          <AssetRow>
            <AssetField>Complex:</AssetField>
            {asset?.complex}
          </AssetRow>
          <AssetRow>
            <AssetField>Axle Count</AssetField>
            {asset.axleCount}
          </AssetRow>
          <AssetRow>
            <AssetField>Wind Rating</AssetField>
            {asset.windRating}
          </AssetRow>
          <AssetRow>
            <AssetField>Roof Type</AssetField>
            {asset.roofType}
          </AssetRow>
          <AssetRow>
            <AssetField>In Service Date:</AssetField>
            {asset.inServiceDate && format(dateFromMMDDYYYY(asset.inServiceDate), 'dd-MMM-yyyy')}
          </AssetRow>
          <AssetRow>
            <AssetField>Orientation:</AssetField>
            {asset?.orientation}
          </AssetRow>
          <AssetRow>
            <AssetField>Layout:</AssetField>
            {asset?.layout}
          </AssetRow>
          <AssetRow>
            <AssetField>Key #:</AssetField>
            {asset?.keyNumber}
          </AssetRow>
          <AssetRow>
            <AssetField>License Plate:</AssetField>
            {asset?.licensePlate}
          </AssetRow>
          <AssetRow>
            <AssetField>VIN #:</AssetField>
            {asset?.vinNumber}
          </AssetRow>
          <AssetRow>
            <AssetField>Steps & Rails:</AssetField>
            {asset?.stepsAndRailsDetails}
          </AssetRow>
          <AssetRow>
            <AssetField>Insured Value:</AssetField>
            {fCurrencyFloat(asset.insuredValue.amount)}
          </AssetRow>
          <AssetRow>
            <AssetField>Capital Cost:</AssetField>
            {fCurrencyFloat(asset.capitalCost.amount)}
          </AssetRow>
          <AssetRow>
            <AssetField>Accumulated Impairment</AssetField>
            {fCurrency(asset.accumulatedImpairment.amount)}{" "}
            {asset.accumulatedImpairment.currency}
          </AssetRow>
          <AssetRow>
            <AssetField>Useful Life (yrs):</AssetField>
            {asset?.usefulLife}
          </AssetRow>
          <AssetRow>
            <AssetField>Residual Value:</AssetField>
            {fCurrency(calcResidualValue(asset))}
          </AssetRow>
          <AssetRow>
            <AssetField>Net Book Value (Starfleet Calc)</AssetField>
            {fCurrency(calcNetBookValue(asset))}
          </AssetRow>
          <AssetRow>
            <AssetField>Monthly Depreciation:</AssetField>
            {fCurrencyFloat(monthlyDepreciation)}
          </AssetRow>
          <AssetRow>
            <AssetField>Accumulated Amortization (Starfleet Calc):</AssetField>
            {fCurrency(calcAccumulatedAmortization(asset))}
          </AssetRow>
        </Box>
      </Card>
        <Box mt={1}>
          <AttachmentList
            documentId={asset?._id}
            disabled={!userCanEditAssets}
            documentType="asset"
          />
        </Box>
    </>
  );
};
export default AssetDetails;
