import React, { FC } from "react";
import {
  TextField,
  Autocomplete,
  AutocompleteProps,
  createFilterOptions,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  FilterOptionsState,
  ListItem,
} from "@mui/material";
import { setBladeMode, simpleGlobalMessage } from "store/slices/systemSlice";
import { initClient } from "store/api/client";
import usePermissions, { ActionType, FeatureType } from "hooks/usePermissions";
import { useAppDispatch } from 'store/store';
import { alphabetizeByProperty } from "utils/formatUtils";
import useLoggedInUser from "hooks/useLoggedInUser";
import useDebounce from "hooks/useDebounce";
import { ClientAutoCompleteResult, useGetClientAutocompleteOptionsQuery } from "store/services/client";



type Props = Omit<
  AutocompleteProps<ClientAutoCompleteResult, boolean, boolean, boolean>,
  "options" | "renderInput"
> & {
  name?: string;
  error?: boolean;
  "data-testId"?: string;
  label?: string;
  onChange: (
    event: React.SyntheticEvent,
    value: ClientAutoCompleteResult,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ClientAutoCompleteResult>,
  ) => void
};

const ClientAutocomplete: FC<Props> = ({
  value,
  name,
  error,
  label,
  onChange,
  ...rest
}) => {
    const [searchValue, setSearchValue] = React.useState("");
    const debouncedSearchValue = useDebounce(searchValue, 500);
    const OPTIONS_LIMIT = 25;
    const { currentData: clients = [], isFetching } = useGetClientAutocompleteOptionsQuery(
      { limit: OPTIONS_LIMIT, searchValue: encodeURIComponent(debouncedSearchValue) },
      { skip: !searchValue }
    );
  const dispatch = useAppDispatch();
  const checkContractPermissions = usePermissions(FeatureType.CLIENTS);
  const canCreateClients = checkContractPermissions(ActionType.CREATE);
  const { userDefaultCountry } = useLoggedInUser()

  function handleChange(
    event: React.SyntheticEvent<Element, Event>,
    newValue: string | ClientAutoCompleteResult | (string | ClientAutoCompleteResult)[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ClientAutoCompleteResult> | undefined
  ) {
    newValue = newValue as ClientAutoCompleteResult;
    if (newValue && newValue.companyName.includes("Create New Client")) {
      handleClientCreate();
    } else {
      onChange(event, newValue, reason, details);
    }
  }

  function handleClientCreate() {
    if (!canCreateClients) {
      dispatch(simpleGlobalMessage("You do not have the permissions required to create clients"));
    } else {
      dispatch(setBladeMode("clientCreate"));
    }
  }
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (client: ClientAutoCompleteResult) => client.companyName
  });

  function addNewClientOptionIfNoClientMatch(
    options: ClientAutoCompleteResult[],
    params: FilterOptionsState<ClientAutoCompleteResult>
  ) {
    const filteredOptions = filterOptions(options, params);
    if (params.inputValue && filteredOptions.length === 0 && !isFetching && searchValue === debouncedSearchValue) {
      filteredOptions.push(
        initClient({country: userDefaultCountry, companyName: `Create New Client "${params.inputValue}"` })
      );
    }
    return filteredOptions;
  }

  return (
    <Autocomplete
      {...rest}
      onChange={handleChange}
      noOptionsText={searchValue ? "No matching clients found" : "Start typing to search"}
      value={value || null}
      key={value?.toString()}
      getOptionLabel={(client) => typeof client === "string" ? client : client.companyName}
      renderOption={(props, client) => {
        return <ListItem {...props} sx={{cursor: "pointer"}} key={client._id}>{client.companyName}</ListItem>;
      }}
      filterOptions={addNewClientOptionIfNoClientMatch}
      options={[...clients].sort(alphabetizeByProperty("companyName"))}
      clearOnBlur={false}
      loading={isFetching || (searchValue !== debouncedSearchValue)}
      renderInput={(params) => (
        <TextField
          name={name}
          {...params}
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          data-testid={rest["data-testId"]}
          error={error}
          margin="none"
          label={label}
          autoComplete="off"
          inputProps={{
            ...params.inputProps,
            autocomplete: "new-password",
            form: { autoComplete: "off" },
          }}
        />
      )}
    />
  );
};

export default ClientAutocomplete;
