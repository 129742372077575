import React from "react";
import { FastField, FastFieldProps } from "formik";
import NumberTextField from "components/NumberTextField";
import { Stack } from "@mui/material";

// Allow users to backspace and clear the field
// 4 --> 46 in an attempt to change value to 6 should be ignored due to too large a value

const FastFtInchInputs: React.FC<{
  label?: string;
  name: string;
  leaveSpaceForHelperText?: boolean;
  allowHelperText?:boolean;
}> = ({ name, leaveSpaceForHelperText, allowHelperText, ...props }) => {
  return (
    <FastField {...props} name={name}>
      {({ field, meta, form }: FastFieldProps) => (
        <Stack direction="row" columnGap={1}>
          <NumberTextField
            {...field}
            value={Number(Math.floor(Number(field.value)).toFixed(0)) || ""}
            onChange={(event) => {
              const inches = getDecimal(Number(field.value)) || 0;
              const newValue = Number(Math.floor(Number(event.currentTarget.value)).toFixed(0)) + getDecimal(inches)
              form.setFieldValue(field.name, newValue)
            }}
            label="ft"
            error={meta.touched && !!meta.error}
            helperText={allowHelperText ? meta.error || leaveSpaceForHelperText ? " " : "" : undefined}
            variant="outlined"
            size="small"
            fullWidth
          />
          <NumberTextField
            {...field}
            sx={{width: 80}}
            value={Number((getDecimal(field.value) * 12).toFixed(0)) || ""}
            onChange={(event) => {
              const inches = Number(event.currentTarget.value)
              if (inches < 12) {
                const feet = inches / 12;
                const newValue = Number(Math.floor(Number(field.value)).toFixed(0)) + getDecimal(feet)
                form.setFieldValue(field.name, newValue)
              }
            }}
            label={"\""}
            error={meta.touched && !!meta.error}
            helperText={allowHelperText ? meta.error || leaveSpaceForHelperText ? " " : "" : undefined}
            variant="outlined"
            size="small"
            fullWidth
          />
        </Stack>
      )}
    </FastField>
  );
};

function getDecimal(number: number) {
  let decimalPart = number % 1;
  return parseFloat(decimalPart.toFixed(10)); // Use toFixed to avoid floating point precision issues
}

export default FastFtInchInputs;
