import React from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/store";
import LoadingOverlay from "components/LoadingOverlay";
import BillOfLadingForm from "./BillOfLadingForm";
import useLoggedInUser from "hooks/useLoggedInUser";
import {
  selectBillOfLadingMovementId,
  selectCurrentBillOfLadingId,
  selectMovementContractId,
  setCurrentBillOfLadingId,
} from "store/slices/contractSlice";
import { setGlobalMessage } from "store/slices/systemSlice";
import { useGetMovementByIdQuery } from "store/services/movement";
import {
  useCreateBillOfLadingMutation,
  useGetBOLByIdQuery,
  useUpdateBillOfLadingMutation,
} from "store/services/billOfLading";
import { useGetClientContactByIdQuery } from "store/services/clientContact";
import { useGetContractByIdQuery } from "store/services/contract";
import { useGetClientByIdQuery } from "store/services/client";
import { useGetAssetByIdQuery } from "store/services/asset";
import { useGetBranchesQuery } from "store/services/branches";
import { useGetYardsQuery } from "store/services/yard";
import { useGetTransportCompanyByIdQuery } from "store/services/transportCompany";
import { blankBillOfLading, IBillOfLading } from "store/models/BillOfLading";
import { Client } from "store/models/Client";
import { ArticleAttributes } from "types/ArticleAttributes";
import { AdditionalItem } from "types/AdditionalItem";
import { initCurrency } from "types/Currency";
import { billOfLadingValidationSchema } from "utils/formikAPI";
import { byIds } from "store/sliceUtils";
import {
  displayAddress,
  getFullName,
  getUserFullName,
  parseSizeCode,
  toMMDDYYYY,
} from "utils/util";

interface Props {
  onClose: () => void;
}

const BillOfLading: React.FC<Props> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { loggedInUser, userDefaultCurrency } = useLoggedInUser();

  const currentBillOfLadingId = useSelector(selectCurrentBillOfLadingId);
  const contractId = useSelector(selectMovementContractId);
  const movementId = useSelector(selectBillOfLadingMovementId) as string;

  const { data: branches = [], isLoading: branchesLoading } = useGetBranchesQuery();
  const { data: yards = [], isLoading: yardsLoading } = useGetYardsQuery();
  
  const { data: contract } = useGetContractByIdQuery(contractId || "", {
    skip: !contractId,
  });
  
  const { data: client } = useGetClientByIdQuery(contract?.client || "", {
    skip: !contract?.client,
  });
  
  const { data: movement, isLoading: movementLoading } = useGetMovementByIdQuery(
    { movementId, contractId },
    { skip: !movementId && !contractId }
  );
  
  const { data: transportCompany } = useGetTransportCompanyByIdQuery(
    movement?.transportCompany || "",
    { skip: !movement?.transportCompany }
  );
  
  const { data: asset, isLoading: assetLoading } = useGetAssetByIdQuery(
    movement?.asset?._id || "",
    { skip: !movement?.asset?._id }
  );
  
  const { data: billOfLading, isLoading: bolLoading } = useGetBOLByIdQuery(
    currentBillOfLadingId || "",
    { skip: !currentBillOfLadingId }
  );
  
  const { data: clientContact, isLoading: clientContactLoading } = useGetClientContactByIdQuery(
    contract?.siteContact || "",
    { skip: !contract?.siteContact || Boolean(currentBillOfLadingId) }
  );


  const [createBillOfLading, { isLoading: isCreatingBol }] = useCreateBillOfLadingMutation();
  const [updateBillOfLading, { isLoading: isUpdatingBol }] = useUpdateBillOfLadingMutation();


  const branchesHash = React.useMemo(() => byIds(branches), [branches]);
  const yardsHash = React.useMemo(() => byIds(yards), [yards]);
  

  const branch = React.useMemo(() => getBranch(), [movement, branchesHash]);
  
  const countryLegalName = branch?.country === "CAN"
    ? "ATCO Structures & Logistics Ltd"
    : "ATCO Structures & Logistics (USA) Inc.";
  
  const yard = asset ? yardsHash[asset.yard] : undefined;
  
  const additionalItems = React.useMemo(() => getAdditionalItems(), [
    movement, 
    userDefaultCurrency, 
    contract?.accessories
  ]);
  
  const initialValues = React.useMemo(
    () => billOfLading || autoPopulateNewBillOfLading(),
    [billOfLading, asset, movement, contract, client, yard, branch, loggedInUser]
  );

  const isLoading = movementLoading || bolLoading || isUpdatingBol || isCreatingBol || 
                   clientContactLoading || assetLoading || branchesLoading || yardsLoading;


  function getBranch() {
    if (movement?.asset?.branch) {
      return branchesHash[movement.asset.branch];
    }
    if (movement?.nonAsset) {
      return branchesHash[movement.nonAsset.branch || ""];
    }
    if (movement?.accessory) {
      return branchesHash[movement.accessory.branch || ""];
    }
    return undefined;
  }

  function getAdditionalItems(): (AdditionalItem & ArticleAttributes)[] {
    if (movement?.nonAsset?.description) {
      return [{
        description: movement.nonAsset.description,
        quantity: 1,
        hazardous: false,
        width: undefined,
        length: undefined,
        height: undefined,
        weight: undefined,
        amount: initCurrency({ currency: userDefaultCurrency }),
        notes: "",
      }];
    }
    
    if (movement?.accessory?._id) {
      return [{
        description: movement.accessory.name || "",
        quantity: 1,
        hazardous: false,
        width: undefined,
        length: undefined,
        height: undefined,
        weight: undefined,
        amount: initCurrency({ currency: userDefaultCurrency }),
        notes: "",
      }];
    }
    
    if (movement?.asset?._id) {
      return accessoriesAssignedToAssetAsAdditionalItems();
    }
    
    return [];
  }

  function accessoriesAssignedToAssetAsAdditionalItems(): (AdditionalItem & ArticleAttributes)[] {
    if (!contract?.accessories || !movement?.asset?._id) return [];
    
    return contract.accessories
      .filter((accessory) => accessory.asset === movement.asset?._id)
      .map((accessory) => ({
        description: accessory.name,
        quantity: accessory.quantity,
        hazardous: false,
        width: undefined,
        length: undefined,
        height: undefined,
        weight: undefined,
        amount: initCurrency({ currency: userDefaultCurrency }),
        notes: "",
      }));
  }

  function getDeclaredValuation() {
    const additionalItemsValue = additionalItems.reduce(
      (acc, item) => acc + (item.amount?.amount || 0), 
      0
    );
    return (asset?.insuredValue.amount || 0) + additionalItemsValue;
  }


  function autoPopulateNewBillOfLading(): IBillOfLading {
    const { width, length } = asset ? parseSizeCode(asset.sizeCode) : { width: 0, length: 0 };
    const height = asset?.height || 0;

    const autoPopulatedFields: Partial<IBillOfLading> = {
      originator: getUserFullName(loggedInUser),
      consignor: determineConsignor(),
      consignorContactName: determineConsignorContactName(),
      consignorAddress: determineConsignorAddress(),
      pointOfOrigin: determinePointOfOrigin(),
      consignorPhone: determineConsignorPhone(),
      deliveryDate: movement?.dateOfMove ? toMMDDYYYY(movement.dateOfMove) : null,
      carrierCompanyName: movement?.transportCompanyName,
      carrierPhone: transportCompany?.phoneNumber || "",
      projectNumber: contract ? contract.projectNumber : "",
      consigneeContactName: determineConsigneeContactName(),
      consigneeName: determineConsigneeName(),
      consigneeContactPhone: determineConsigneePhone(),
      consigneeAddress: determineConsigneeAddress(),
      consigneeDestination: determineConsigneeDestination(),
      declaredValuation: getDeclaredValuation(),
      billTo: contract?.billTo || null,
      assetArticles: movement?.asset
        ? [
            {
              asset: movement.asset._id,
              attributes: {
                width,
                length,
                height,
                weight: 0,
                amount: asset?.insuredValue.amount
                  ? asset.insuredValue
                  : initCurrency({ currency: userDefaultCurrency }),
                hazardous: false,
                notes: "",
              },
            },
          ]
        : [],
      additionalItems,
    };

    return { ...blankBillOfLading(), ...autoPopulatedFields };
  }

  const handleSave = (billOfLading: IBillOfLading) => {
    if (!movement) return;

    const successMessage = (bolNumber: string) => ({
      messageText: `Successfully ${billOfLading._id ? 'updated' : 'created'} BOL ${bolNumber}`,
      severity: "success" as const,
      show: true,
    });

    const errorHandler = (error: any) => {
      dispatch(
        setGlobalMessage({
          messageText: error.data?.message || "An error occurred",
          severity: "error",
          show: true,
        })
      );
    };

    if (billOfLading._id) {
      updateBillOfLading({
        billOfLading,
        contractId: movement?.contract?._id || contractId,
        movementId: movement._id,
      })
        .unwrap()
        .then(() => dispatch(setGlobalMessage(successMessage(billOfLading.billOfLadingNumber))))
        .catch(errorHandler);
    } else {
      const { _id, ...rest } = billOfLading;
      createBillOfLading({
        billOfLading: rest,
        movementId,
        contractId: movement.contract?._id || contractId,
      })
        .unwrap()
        .then((bol) => {
          dispatch(setCurrentBillOfLadingId(bol._id));
          dispatch(setGlobalMessage(successMessage(billOfLading.billOfLadingNumber)));
        })
        .catch(errorHandler);
    }
  };

  function determineConsignor() {
    // OUT Movement - ATCO
    // IN Movement - Client Legal Name
    // Relocate Movement - ATCO
    if (movement?.type === "IN") {
      return (client as Client)?.legalName || "";
    }
    return loggedInUser?.countries[0] === "USA"
      ? "ATCO Structures & Logistics (USA) Inc."
      : "ATCO Structures & Logistics Ltd";
  }

  function determineConsignorContactName() {
    // OUT Movement - Logged In User
    // IN Movement - Client Contact
    // Relocate Movement - Logged In User
    if (movement?.type === "IN") {
      return clientContact ? getFullName(clientContact) : "";
    }
    return getFullName(loggedInUser);
  }

  function determineConsignorAddress() {
    // OUT Movement - ATCO Yard or Branch address
    // IN Movement - Contract Ship To address
    // Relocate Movement - Blank
    if (!client || !contract) {
      if (!asset) {
        return branch?.address.address || "Address not found";
      }
      return (
        yard?.address.address || branch?.address.address || "Address not found"
      );
    }

    if (movement?.type === "IN") {
      return displayAddress(contract.shipTo);
    }
    if (movement?.type === "OUT") {
      return (
        yard?.address.address || branch?.address.address || "Address not found"
      );
    }
    return "";
  }

  function determineConsigneeName() {
    // OUT Movement - Client Legal Name
    // IN Movement - ATCO
    // Relocate Movement - BLANK
    if (!client) return "";

    if (movement?.type === "IN") {
      return countryLegalName;
    }
    if (movement?.type === "OUT") {
      return client.legalName;
    }
    return "";
  }

  function determineConsigneeContactName() {
    // OUT Movement - Client Contact
    // IN Movement - Logged In User
    // Relocate Movement - Logged In User
    if (movement?.type === "OUT") {
      return contract?.siteContact ? getFullName(clientContact) : "";
    }
    return getFullName(loggedInUser);
  }

  function determineConsigneePhone() {
    // OUT Movement - Client Contact Phone || client phone
    // IN Movement - Logged In User Phone
    // Relocate Movement - Logged In User Phone
    if (movement?.type === "OUT") {
      return contract?.siteContact
        ? clientContact?.primaryPhone
        : client?.phoneNumber || "";
    }
    return loggedInUser?.phone || "";
  }

  function determineConsigneeDestination() {
      // OUT Movement - Contract Ship To address
      // IN Movement - Yard or Branch address
      // Relocate Movement - Blank
    if (!client || !contract) return "";
    
    if (movement?.type === "IN") {
      return yard?.address.address || branch?.address.address || "Address not found";
    }
    if (movement?.type === "OUT") {
      return displayAddress(contract.shipTo);
    }
    return "";
  }

  function determineConsignorPhone() {
      // OUT Movement - Logged In User Phone
      // IN Movement - Client Contact Phone
      // Relocate Movement - Logged In User Phon
    if (movement?.type === "IN") {
      return clientContact?.primaryPhone || "";
    }
    return loggedInUser?.phone || "";
  }

  function determinePointOfOrigin() {
    // OUT Movement - ATCO Yard or Branch address
    // IN Movement - Contract Ship To address
    // Relocate Movement - Blank
    if (!contract) return "";

    if (movement?.type === "OUT") {
      return yard?.address.address || branch?.address.address || "Address not found";
    }
    if (movement?.type === "IN") {
      return displayAddress(contract.shipTo);
    }
    return "";
  }

  function determineConsigneeAddress() {
    // IN Movement - ATCO yard address or branch address
    // OUT Movement - Contract Ship To address
    // Relocate Movement - Blank
    if (!contract) return "";

    if (movement?.type === "OUT") {
      return displayAddress(contract.shipTo);
    }
    if (movement?.type === "IN") {
      return (
        yard?.address.address || branch?.address.address || "Address not found"
      );
    }
    return "";
  }

  return (
    <LoadingOverlay loading={isLoading}>
      <Formik
        initialValues={initialValues}
        validationSchema={billOfLadingValidationSchema}
        onSubmit={handleSave}
        enableReinitialize
      >
        <BillOfLadingForm onClose={onClose} isLoading={isLoading} />
      </Formik>
    </LoadingOverlay>
  );
};

export default BillOfLading;