import { Box, Card, CardContent, CardHeader, TextField, Typography } from "@mui/material";
import LoadingComponent from "components/LoadingComponent";
import _ from "lodash";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Asset } from "store/models/Asset";
import { IContractLog } from "store/models/ContractHistory";
import UserData from "store/models/UserData";
import { useGetAssetsByContractIdQuery } from "store/services/asset";
import { useGetContractHistoryQuery } from "store/services/contract";
import { useGetUsersQuery } from "store/services/user";
import { setCurrentAssetId } from "store/slices/assetSlice";
import { selectCurrentContractId } from "store/slices/contractSlice";
import { setBladeMode } from "store/slices/systemSlice";
import { byIds } from "store/sliceUtils";
import { useAppDispatch } from "store/store";
import { getFullName, toDDMMMYYYY } from "utils/util";

interface Props {}

const ContractHistroy: FC<Props> = () => {
  const [searchValue, setSearchValue] = React.useState("");
  const currentContractId = useSelector(selectCurrentContractId);
  const { data: assets = [] } = useGetAssetsByContractIdQuery(
    currentContractId || "",
    { skip: !currentContractId }
  );
  const { data: users = [] } = useGetUsersQuery();
  const usersHash = byIds(users);
  const assetsHash = byIds(assets);
  const {
    data: contractHistory,
    isFetching,
    currentData,
  } = useGetContractHistoryQuery(currentContractId || "", {
    skip: !currentContractId,
    refetchOnMountOrArgChange: true,
  });

  const sortedContractHistory = _.orderBy(
    contractHistory?.updateLog,
    "updatedDate",
    "desc"
  );

  return (
    <LoadingComponent isLoading={isFetching}>
      <TextField
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        size="small"
        fullWidth
        placeholder="Search"
        label="Search"
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {sortedContractHistory.length ? (
          sortedContractHistory
            .filter((log) =>
              log.updateDescription.toLowerCase().includes(searchValue.toLowerCase()) || getFullName(usersHash[log.updatedBy]).toLowerCase().includes(searchValue.toLowerCase())
            )
            .map((updateLog, index) => (
              <ContractHistoryCard
                key={index}
                updateLog={updateLog}
                contractAssets={assetsHash}
                users={usersHash}
              />
            ))
        ) : (
          <Typography sx={{ alignSelf: "center", mt: 5 }}>
            No Contract Changes Yet!
          </Typography>
        )}
      </Box>
    </LoadingComponent>
  );
}

export default ContractHistroy;

interface CardProps {
  updateLog:  IContractLog
  contractAssets: Record<string, Asset>
  users: Record<string, UserData>
};


const ContractHistoryCard: FC<CardProps> = ({updateLog, contractAssets, users}) => {

  const dispatch = useAppDispatch()


  const handleSerialClick = (serial: string) => {
    const assetId = Object.keys(contractAssets).find(key => contractAssets[key].serialNumber === serial)
    if (!assetId) return
    dispatch(setCurrentAssetId(assetId))
    dispatch(setBladeMode("assetViewEdit"))
  }

  const updateDescription = updateLog.updateDescription
  const array = parseStringWithLinks(updateDescription)
  const serialRegex = /\/assets\/(\d+)/
  const arrayWithRawSerialNumber = array.map((item, index) => {
    if (item.includes("href")) {
      const serialMatch = item.match(serialRegex)
      const serial = serialMatch ? serialMatch[1] : ''
      return (
        <Typography sx={{cursor: "pointer"}} color="primary" key={item} onClick={() => handleSerialClick(serial)}>{serial}</Typography>
      )
    } else {
      return <Typography key={item}>{item}</Typography>;
    }
  })

  return (
    <Card>
      <CardHeader titleTypographyProps={{variant: 'subtitle1'}} title={toDDMMMYYYY(updateLog.updatedDate)} />
      <CardContent sx={{paddingTop: 1}}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box sx={{ display: "flex", flexWrap: 'wrap', gap: 1 }}>
            {arrayWithRawSerialNumber.map((item) => item)}
          </Box>{" "}
          {updateLog.updatedBy && <Typography fontWeight="bold">by {getFullName(users[updateLog.updatedBy])}</Typography>}
        </Box>
      </CardContent>
    </Card>
  );
}


function parseStringWithLinks(input: string) {

  const regex = /(<a.*?>.*?<\/a>)/g;
  const parts = input.split(regex);
  
  return parts
}