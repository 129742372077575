import React, { FC } from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  Font
} from "@react-pdf/renderer";
import ATCOLogo from "./atco-logo.png";
import { IBillOfLading } from "store/models/BillOfLading";
import { Asset } from "store/models/Asset";
import { Branch } from "store/models/Branch";
import { parseAddress } from "store/models/Address";
import {
  dateFromMMDDYYYY,
  feetToFeetInches,
  getTimezone,
  objectIdToDate,
  parsePhoneNumberOfDubiousLength,
  toDDMMMYYYY,
} from "utils/util";
import { isValid } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

function formatTime(date: Date) {
  return isValid(date)
    ? formatInTimeZone(new Date(date), getTimezone(), "hh:mm a z")
    : "";
}

function formatDate(date: Date) {
  return isValid(date)
    ? formatInTimeZone(new Date(date), getTimezone(), "MMMM dd, yyyy h:mm a zzz")
    : "";
}

const AddressInfo = ({ branch, bol }: { branch: Branch | undefined, bol: IBillOfLading }) => (
  <View
    style={{
      width: "33%",
      fontSize: 6,
      display: "flex",
      rowGap: 2,
      flexDirection: "column",
      marginLeft: 8,
    }}
  >
    <Text style={{ textAlign: "right", fontSize: 12 }}>BOL # {bol.billOfLadingNumber}</Text>
    <Text style={{ textAlign: "right" }}>ATCO Structures & Logistics Ltd.</Text>
    <Text style={{ textAlign: "right" }}>{parseAddress(branch?.address)}</Text>
    <Text style={{ textAlign: "right" }}>T.: +1 (403) 292-7600 Fax: 403-292-7624</Text>
    <Text style={{ textAlign: "right" }}>Toll Free: 1-800-575-2826</Text>
    <Text style={{ textAlign: "right" }}>https://structures.atco.com/</Text>
  </View>
);

const Logo: FC<{ movementType: "IN" | "OUT" | "RELOCATE" }> = ({
  movementType,
}) => (
  <View style={{ width: "33%" }}>
    <Image style={{ width: 100 }} src={ATCOLogo} />
    <View
      style={{
        backgroundColor: "#d7d7d7",
        padding: 2,
        borderRadius: 4,
        alignItems: "center",
        alignContent: "center",
        width: 100,
        marginTop: 4,
      }}
    >
      <Text>{movementType}</Text>
    </View>
  </View>
);

const FormField = ({
  height,
  width,
  name,
  value,
  suffix,
  style,
}: {
  height?: string | number;
  width: string | number;
  name: string;
  value: string | number;
  suffix?: string;
  style?: { [name: string]: string | number };
}) => (
  <View
    style={{
      width,
      height,
      borderRadius: 4,
      borderWidth: 1,
      padding: 2,
      ...style,
    }}
  >
    <Text style={{ fontSize: 6, fontWeight: "bold" }}>{name.toLocaleUpperCase()}</Text>
    <View style={{ flexDirection: "row", justifyContent: "space-between", margin: 1 }}>
      <Text style={{ fontSize: 8 }}>{value || " "}</Text>
      <Text style={{ fontSize: 6, paddingBottom: 4, paddingRight: 2 }}>
        {suffix}
      </Text>
    </View>
  </View>
);

const TopSection = (props: { bol: IBillOfLading }) => (
  <View
    style={{
      width: "100%",
      alignItems: "center",
    }}
  >
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 6,
        alignItems: "center",
        columnGap: 6,
      }}
    >
      <FormField width="75%" name="ORIGIN" value={props.bol.pointOfOrigin} />
      <FormField
        width="25%"
        name="DATE"
        value={
          props.bol.deliveryDate
            ? toDDMMMYYYY(dateFromMMDDYYYY(props.bol.deliveryDate))!
            : ""
        }
      />
    </View>
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 6,
        alignItems: "center",
        columnGap: 6,
      }}
    >
      <FormField width="50%" name="consignor" value={props.bol.consignor} />
      <FormField
        width="25%"
        name="CONTACT NAME"
        value={props.bol.consignorContactName}
      />
      <FormField
        width="25%"
        name="project no."
        value={props.bol.projectNumber}
      />
    </View>
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 6,
        alignItems: "center",
        columnGap: 6,
      }}
    >
      <FormField
        width="50%"
        name="CONSIGNOR ADDRESS"
        value={props.bol.consignorAddress}
      />
      <FormField
        width="25%"
        name="CONTACT PHONE"
        value={parsePhoneNumberOfDubiousLength(props.bol.consignorPhone)}
      />

      <FormField
        width="25%"
        name="PURCHASE ORDER NO."
        value={props.bol.purchaseOrderNumber}
      />
    </View>
  </View>
);

const Disclaimer = () => (
  <View style={{ fontSize: 6, marginTop: 6, padding: "0 4" }}>
    <Text>
      Received at the point of origin on the date specified, from the consignor
      mentioned herein, the property herein described, in apparent good order,
      except as noted (contents and conditions of contents of package unknown)
      marked, consigned and destined as indicated below, which the carrier
      agrees to carry and to deliver to the consignee at the said destination,
      if on its own authorized route or otherwise to cause to be carried by
      another carrier on the route to said destination, subject to the rates and
      classification in effect on the date of shipment. It is mutually agreed as
      to each carrier of all or any of the goods over all or any portion of the
      route to destination, and as to each party of any new time interested in
      all or any of the goods, that every service to be performed hereunder
      shall be subject to all the conditions not prohibited by law, whether
      permitted or written, which are hereby agreed by the consignor and
      accepted for its consignor and its assigns.
    </Text>
  </View>
);

const HazardousMaterialInstruction = () => (
  <View style={{ fontSize: 6, marginTop: 2, marginLeft: 2 }}>
    <Text>
      Hazardous Materials marked an &quot;X&quot; above require Carrier to
      comply with all applicable TDG regulations.
    </Text>
  </View>
);

const SpecialAgreementClause = ({ bol }: { bol: IBillOfLading }) => (
  <View style={{ fontSize: 6, marginTop: 6, width: "50%" }}>
    <FormField
      width="100%"
      name="Special agreement between consignor and carrier"
      value={bol.specialAgreementBetweenConsignorAndCarrier}
    />
    <Text style={{ width: "100%", marginLeft: 2, marginTop: 1 }}>
      {" "}
    </Text>
  </View>
);

const DeclaredValuationClause = ({ bol }: { bol: IBillOfLading }) => (
  <View style={{ fontSize: 6, marginTop: 6, width: "50%" }}>
    <FormField
      width="100%"
      name="DECLARED VALUATION"
      value={bol.declaredValuation || ""}
    />
    <Text style={{ width: "100%", marginLeft: 2, marginTop: 1 }}>
      Maximum liability of $2.00 per pound or $4.41 per kilogram unless declared
      valuation states otherwise.
    </Text>
  </View>
);

const CarrierInfo = ({ bol }: { bol: IBillOfLading }) => (
  <View
    style={{ fontSize: 6, marginTop: 6, flexDirection: "row", columnGap: 4 }}
  >
    <FormField
      width="50%"
      name="CARRIER COMPANY NAME"
      value={bol.carrierCompanyName}
    />
    <FormField
      width="50%"
      name="CARRIER PHONE"
      value={parsePhoneNumberOfDubiousLength(bol.carrierPhone)}
    />
  </View>
);

const Comments = ({ bol }: { bol: IBillOfLading }) => (
  <FormField
    style={{ marginTop: 4 }}
    width="100%"
    height={50}
    name="COMMENTS"
    value={bol.comments}
  />
);

const Acknowledgements = ({ bol }: { bol: IBillOfLading }) => (
  <>
    <View
      style={{
        width: "100%",
        fontSize: 8,
        flexDirection: "row",
        columnGap: 4,
        marginTop: 4,
      }}
    >
      <Text style={{ width: "50%" }}>ACKNOWLEDGEMENT AT ORIGIN</Text>
      <Text style={{ width: "50%" }}>ACKNOWLEDGEMENT AT DESTINATION</Text>
    </View>
    <View
      style={{
        width: "100%",
        fontSize: 8,
        flexDirection: "row",
        columnGap: 4,
        marginTop: 4,
      }}
    >
      <FormField
        width="30%"
        name="NAME / SIGNATURE OF CONSIGNOR"
        value={bol.acknowledgementAtOriginConsignorName}
      />
      <FormField
        width="20%"
        name="DATE"
        value={bol.acknowledgementAtOriginConsignorDate || ""}
      />
      <FormField
        width="30%"
        name="NAME / SIGNATURE OF CONSIGNEE"
        value={bol.acknowledgementAtDestinationConsigneeName}
      />
      <FormField
        width="20%"
        name="DATE"
        value={bol.acknowledgementAtDestinationConsigneeDate || ""}
      />
    </View>
    <View
      style={{
        width: "100%",
        fontSize: 8,
        flexDirection: "row",
        columnGap: 4,
        marginTop: 4,
      }}
    >
      <FormField
        width="30%"
        name="ACKNOWLEDGEMENT OF CARRIER"
        value={bol.acknowledgementAtOriginCarrierName}
      />
      <FormField
        width="20%"
        name="DATE"
        value={bol.acknowledgementAtOriginCarrierDate || ""}
      />
      <FormField
        width="30%"
        name="ACKNOWLEDGEMENT OF CARRIER"
        value={bol.acknowledgementAtDestinationCarrierName}
      />
      <FormField
        width="20%"
        name="DATE"
        value={bol.acknowledgementAtDestinationCarrierDate || ""}
      />
    </View>
  </>
);

const AtDestinationFields = ({ bol }: { bol: IBillOfLading }) => {
  function formatMinutes(minutes: number | null) {
    if (!minutes) {
      return "";
    }
    if (minutes < 60) {
      return `${minutes} mins`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return remainingMinutes === 0 
      ? `${hours} hrs` 
      : `${hours} hrs, ${remainingMinutes} mins`;
  }
  
  return (
    <View>
      <Text
        style={{
          width: "100%",
          marginTop: 8,
          fontSize: 6,
          borderBottom: "1",
          borderColor: "#d7d7d7",
          borderStyle: "solid",
        }}
      >
        TO BE COMPLETED BY CARRIER OR ATCO STRUCTURES
      </Text>
      <View
        style={{
          width: "100%",
          marginTop: 4,
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 4,
        }}
      >
        <View
          style={{
            width: "50%",
            flexDirection: "column",
            gap: 4,
            marginTop: 2,
          }}
        >
          <Text
            style={{
              width: "100%",
              fontSize: 6,
            }}
          >
            TIME AT ORIGIN
          </Text>
          <View style={{ flexDirection: "row", width: "100%", gap: 4 }}>
            <FormField
              width="50%"
              name="SITE ARRIVAL"
              value={
                bol.consignorArrivalTime
                  ? formatTime(new Date(bol.consignorArrivalTime))
                  : ""
              }
              suffix="AM / PM"
              height={20}
              style={{ gap: 2 }}
            />
            <FormField
              width="50%"
              name="TOTAL LOADING TIME"
              value={formatMinutes(bol.consignorLoadingTimeTotalMinutes)}
              suffix="HRS/MINS"
              height={20}
              style={{ gap: 2 }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              width: "50%",
              gap: 4,
              justifyContent: "flex-start",
            }}
          >
            <FormField
              width="100%"
              name="SITE DEPARTURE"
              value={
                bol.consignorDepartureTime
                  ? formatTime(new Date(bol.consignorDepartureTime))
                  : ""
              }
              suffix="AM / PM"
              height={20}
              style={{ gap: 2 }}
            />
          </View>
        </View>
        <View
          style={{
            width: "50%",
            flexDirection: "column",
            gap: 4,
            marginTop: 2,
          }}
        >
          <Text
            style={{
              width: "100%",
              fontSize: 6,
            }}
          >
            TIME AT DESTINATION
          </Text>
          <View style={{ flexDirection: "row", width: "100%", gap: 4 }}>
            <FormField
              width="50%"
              name="SITE ARRIVAL"
              value={
                bol.consigneeSiteArrivalTime
                  ? formatTime(new Date(bol.consigneeSiteArrivalTime))
                  : ""
              }
              suffix="AM / PM"
              height={20}
              style={{ gap: 2 }}
            />
            <FormField
              width="50%"
              name="TOTAL UNLOADING TIME"
              value={formatMinutes(bol.consigneeUnloadingTimeTotalMinutes)}
              suffix="HRS/MINS"
              height={20}
              style={{ gap: 2 }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              width: "50%",
              gap: 4,
              justifyContent: "flex-start",
            }}
          >
            <FormField
              width="100%"
              name="SITE DEPARTURE"
              value={
                bol.consigneeSiteDepartureTime
                  ? formatTime(new Date(bol.consigneeSiteDepartureTime))
                  : ""
              }
              suffix="AM / PM"
              height={20}
              style={{ gap: 2 }}
            />
          </View>
        </View>
      </View>

      <View
        style={{
          width: "100%",
          marginTop: 4,
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 4,
        }}
      >
        <View
          style={{
            justifyContent: "center",
            width: "25%",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 7, width: "75%" }}>
            ADDITIONAL TIME AT ORIGIN OR DESTINATION (EXPLAIN)
          </Text>
        </View>
        <View style={{ width: "25%", gap: 2 }}>
          <FormField
            width="100%"
            value={formatMinutes(
              bol.consigneeOtherTimeAtOriginOrDestinationTotalMinutes1
            )}
            suffix="HRS/MINS"
            height={20}
            name=" "
          />
          <FormField
            width="100%"
            value={formatMinutes(
              bol.consigneeOtherTimeAtOriginOrDestinationTotalMinutes2
            )}
            suffix="HRS/MINS"
            height={20}
            name=" "
          />
        </View>
        <View style={{ width: "50%", gap: 2 }}>
          <FormField
            width="100%"
            name="REASON"
            value={bol.consigneeOtherTimeAtOriginOrDestinationExplanation1}
            height={20}
          />
          <FormField
            width="100%"
            name="REASON"
            value={bol.consigneeOtherTimeAtOriginOrDestinationExplanation2}
            height={20}
          />
        </View>
      </View>
    </View>
  );
};

const MiddleSection = (props: { bol: IBillOfLading }) => (
  <View
    style={{
      width: "100%",
      marginTop: 6,
      alignItems: "center",
    }}
  >
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        columnGap: 6,
      }}
    >
      {objectIdToDate(props.bol._id) < new Date("2024-07-03") ? (
        <>
          <FormField
            width="50%"
            name="SCHEDULED PICKUP TIME AT ORIGIN"
            value={
              props.bol.scheduledPickupTimeAtOrigin
                ? formatTime(new Date(props.bol.scheduledPickupTimeAtOrigin))
                : ""
            }
            suffix="AM / PM"
          />
          <FormField
            width="50%"
            name="SCHEDULED DELIVERY TIME AT DESTINATION"
            value={
              props.bol.scheduledDeliveryTimeAtDestination
                ? formatTime(
                    new Date(props.bol.scheduledDeliveryTimeAtDestination)
                  )
                : ""
            }
            suffix="AM / PM"
          />
        </>
      ) : (
        <>
          <FormField
            width="50%"
            name="SCHEDULED PICKUP AT ORIGIN"
            value={
              props.bol.scheduledPickupTimeAtOrigin
                ? formatDate(new Date(props.bol.scheduledPickupTimeAtOrigin))
                : ""
            }
          />
          <FormField
            width="50%"
            name="SCHEDULED DELIVERY AT DESTINATION"
            value={
              props.bol.scheduledDeliveryTimeAtDestination
                ? formatDate(
                    new Date(props.bol.scheduledDeliveryTimeAtDestination)
                  )
                : ""
            }
          />
        </>
      )}
    </View>
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 6,
        alignItems: "center",
        columnGap: 6,
      }}
    >
      <FormField
        width="100%"
        name="DESTINATION"
        value={props.bol.consigneeDestination}
      />
    </View>
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 6,
        alignItems: "center",
        columnGap: 6,
      }}
    >
      <FormField
        width="66%"
        name="CONSIGNEE NAME"
        value={props.bol.consigneeName}
      />
      <FormField
        width="34%"
        name="CONTACT NAME"
        value={props.bol.consigneeContactName}
      />
    </View>
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 6,
        alignItems: "center",
        columnGap: 6,
      }}
    >
      <FormField
        width="66%"
        name="CONSIGNEE ADDRESS"
        value={props.bol.consigneeAddress}
      />
      <FormField
        width="34%"
        name="CONTACT PHONE"
        value={parsePhoneNumberOfDubiousLength(props.bol.consigneeContactPhone)}
      />
    </View>
  </View>
);

const tableColumnWidths = {
  "# of Packages": "7%",
  Name: "51%",
  HM: "7%",
  Width: "7%",
  Length: "7%",
  Height: "7%",
  Weight: "7%",
  $: "7%",
};

const AssetTable = ({
  assets = [],
  bol,
}: {
  bol?: IBillOfLading;
  assets?: Asset[];
}) => (
  <>
    {/* TABLE HEADERS */}
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        fontSize: 6,
        marginTop: 10,
        marginBottom: 3,
      }}
    >
      <Text
        style={{
          width: tableColumnWidths["# of Packages"],
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        # of pkgs
      </Text>
      <Text
        style={{
          width: tableColumnWidths.Name,
          textAlign: "left",
          fontWeight: 600,
        }}
      >
        Particulars of goods, marks and exceptions (dimensions, weights, and amounts are approximate)
      </Text>
      <Text
        style={{
          width: tableColumnWidths.HM,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        HM &quot;R&quot;
      </Text>
      <Text
        style={{
          width: tableColumnWidths.Width,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Width
      </Text>
      <Text
        style={{
          width: tableColumnWidths.Length,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Length
      </Text>
      <Text
        style={{
          width: tableColumnWidths.Height,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Height
      </Text>
      <Text
        style={{
          width: tableColumnWidths.Weight,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Weight
      </Text>
      <Text
        style={{
          width: tableColumnWidths.$,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Amount ($)
      </Text>
    </View>

    {/* ASSET ARTICLES */}
    <View style={{ width: "100%", borderWidth: 1, borderRadius: 4 }}>
      <>
        {assets.map(
          ({ _id, serialNumber, category, subCategory, floorStyle }, index) => (
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                fontSize: 7,
                textAlign: "center",
                alignItems: "center",
                paddingTop: 3,
                paddingBottom: 3,
                borderBottomWidth: 1,
                borderColor: "#d7d7d7",
              }}
            >
              <Text
                key={_id}
                style={{ width: tableColumnWidths["# of Packages"] }}
              >
                1
              </Text>
              <View key={_id} style={{ width: tableColumnWidths.Name }}>
                <Text style={{ textAlign: "left" }}>
                  <Text style={{ fontSize: 8, fontWeight: "bold" }}>
                    {serialNumber || "No Serial"}{" "}
                  </Text>
                  - {category || "No category"} - {subCategory || ""} -{" "}
                  {floorStyle || "UNSPECIFIED FLOORTYPE"}
                </Text>
                <Text>
                  {bol?.assetArticles[0].attributes.notes
                    ? `Notes: ${bol?.assetArticles[0].attributes.notes}`
                    : ""}
                </Text>
              </View>
              <Text key={_id} style={{ width: tableColumnWidths.HM }}>
                {bol?.assetArticles[index].attributes.hazardous ? "X" : ""}
              </Text>
              <Text key={_id} style={{ width: tableColumnWidths.Width }}>
                {bol?.assetArticles[index].attributes.width
                  ? feetToFeetInches(bol?.assetArticles[index].attributes.width)
                  : ""}
              </Text>
              <Text key={_id} style={{ width: tableColumnWidths.Length }}>
                {bol?.assetArticles[index].attributes.length
                  ? feetToFeetInches(bol?.assetArticles[index].attributes.length)
                  : ""}
              </Text>
              /
              <Text key={_id} style={{ width: tableColumnWidths.Height }}>
                {bol?.assetArticles[index].attributes.height
                  ? feetToFeetInches(bol?.assetArticles[index].attributes.height)
                  : ""}
              </Text>
              <Text key={_id} style={{ width: tableColumnWidths.Weight }}>
                {bol?.assetArticles[index].attributes.weight}
              </Text>
              <Text key={_id} style={{ width: tableColumnWidths.$ }}>
                {bol?.assetArticles[index].attributes.amount
                  ? `${bol?.assetArticles[index].attributes.amount.amount.toLocaleString()} ${bol?.assetArticles[0].attributes.amount?.currency}`
                  : "-"}
              </Text>
            </View>
          )
        )}

        {/* ADDITIONAL ITEMS */}
        {bol?.additionalItems.map(
          (
            {
              quantity,
              description,
              hazardous,
              width,
              length,
              height,
              weight,
              amount,
            },
            index
          ) => (
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                fontSize: 7,
                textAlign: "center",
                alignItems: "center",
                paddingTop: 3,
                paddingBottom: 3,
                borderBottomWidth: 1,
                borderColor: "#d7d7d7",
              }}
            >
              <Text
                key={`assetArticle-${index}`}
                style={{ width: tableColumnWidths["# of Packages"] }}
              >
                {quantity}
              </Text>

              <View
                key={`assetArticle-${index}`}
                style={{
                  width: tableColumnWidths.Name,
                  alignItems: "flex-start",
                }}
              >
                <Text>{description}</Text>
                <Text>
                  {bol?.additionalItems[index].notes
                    ? `Notes: ${bol?.additionalItems[index].notes}`
                    : ""}
                </Text>
              </View>
              <Text
                key={`assetArticle-${index}`}
                style={{ width: tableColumnWidths.HM }}
              >
                {hazardous ? "X" : ""}
              </Text>
              <Text
                key={`assetArticle-${index}`}
                style={{ width: tableColumnWidths.Width }}
              >
                {width ? feetToFeetInches(width) : ""}
              </Text>
              <Text
                key={`assetArticle-${index}`}
                style={{ width: tableColumnWidths.Length }}
              >
                {length ? feetToFeetInches(length) : ""}
              </Text>
              <Text
                key={`assetArticle-${index}`}
                style={{ width: tableColumnWidths.Height }}
              >
                {height ? feetToFeetInches(height) : ""}
              </Text>
              <Text
                key={`assetArticle-${index}`}
                style={{ width: tableColumnWidths.Weight }}
              >
                {weight}
              </Text>
              <Text
                key={`assetArticle-${index}`}
                style={{ width: tableColumnWidths.$ }}
              >
                {amount?.amount ? `${amount?.amount} ${amount?.currency}` : ""}
              </Text>
            </View>
          )
        )}
      </>
    </View>
  </>
);

const BillOfLadingPdf = ({
  billOfLading,
  assets,
  branch,
  movementType,
}: {
  billOfLading: IBillOfLading;
  assets: Asset[];
  branch: Branch | undefined;
  movementType: "IN" | "OUT" | "RELOCATE";
}) => {
  console.log({billOfLading})
  Font.register({
    family: 'Roboto',
    fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
  });

  return (
    (<Document>
      <Page
        size="LETTER"
        style={{
          padding: 20,
          fontFamily: "Roboto"
        }}
      >
        <View style={{ flexDirection: "row", columnGap: 4, alignItems: "flex-start", paddingTop: 2, paddingBottom: 2 }}>
          <Logo movementType={movementType} />
          <View style={{ width: "33%" }}>
            <Text style={{ fontSize: 24 }}>BILL OF LADING</Text>
            <Text style={{ fontSize: 8, textAlign: "center", marginRight: 4 }}>
              NON NEGOTIABLE
            </Text>
          </View>
          <AddressInfo branch={branch} bol={billOfLading} />
        </View>

        <TopSection bol={billOfLading} />

        <Disclaimer />

        <MiddleSection bol={billOfLading} />

        <AssetTable assets={assets} bol={billOfLading} />

        <HazardousMaterialInstruction />

        <View style={{ flexDirection: "row", columnGap: 4 }}>
          <SpecialAgreementClause bol={billOfLading} />
          <DeclaredValuationClause bol={billOfLading} />
        </View>


        <CarrierInfo bol={billOfLading} />

        <AtDestinationFields bol={billOfLading} />

        <Comments bol={billOfLading} />

        <Acknowledgements bol={billOfLading} />
      </Page>
     </Document>)
  );
};

export default BillOfLadingPdf;
