import { Dialog } from "@mui/material";
import BillOfLading from "features/billOfLading/BillOfLading";
import React from "react";
import { useSelector } from "react-redux";
import {
  setCurrentBillOfLadingId,
} from "store/slices/contractSlice";
import { selectModalMode, setModalMode } from "store/slices/systemSlice";
import { useAppDispatch } from 'store/store';

const BillOfLadingModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalMode = useSelector(selectModalMode);
  const isOpen = modalMode === "billOfLadingCreate" || modalMode === "billOfLadingViewEdit";

  const closeMovementModal = () => {
    dispatch(setModalMode("none"));
    dispatch(setCurrentBillOfLadingId(undefined));
  }

  return isOpen ? (
    <Dialog
      open={isOpen}
      onClose={closeMovementModal}
      fullScreen
    >
      <BillOfLading onClose={closeMovementModal} />
    </Dialog>
  ) : null;
};
export default BillOfLadingModal;
