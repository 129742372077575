import React, { useEffect, useMemo } from "react";
import { Form, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/store";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import {
  selectBillOfLadingMovementId,
  selectCurrentBillOfLadingId,
  selectMovementContractId,
} from "store/slices/contractSlice";
import { setGlobalMessage } from "store/slices/systemSlice";
import FastTextInput from "components/formik/FastTextInput";
import FastNumberInput from "components/formik/FastNumberInput";
import FastPhoneInput from "components/formik/FastPhoneInput";
import FastDateInput from "components/formik/FastDateInput";
import FastTimeInput from "components/formik/FastTimeInput";
import FastHrsMinsInput from "components/formik/FastHrsMinsInput";
import BillOfLadingArticles from "features/billOfLading/BillOfLadingArticles";
import BillOfLadingPdf from "./BillOfLadingPdf";
import usePermissions, { FeatureType, ActionType } from "hooks/usePermissions";
import { displayAddress } from "utils/util";
import { byIds } from "store/sliceUtils";
import { useGetMovementByIdQuery } from "store/services/movement";
import {
  useGetBOLByIdQuery,
} from "store/services/billOfLading";
import { useGetContractByIdQuery } from "store/services/contract";
import { useGetClientByIdQuery } from "store/services/client";
import { useGetAssetByIdQuery } from "store/services/asset";
import { useGetBranchesQuery } from "store/services/branches";
import { useGetYardsQuery } from "store/services/yard";
import { IBillOfLading } from "store/models/BillOfLading";
import { ReactComponent as PdfIcon } from "svg/pdf.svg";
import CurrencyTextField from "components/CurrencyTextField";

interface Props {
  onClose: () => void;
  isLoading: boolean;
}

const BillOfLadingForm: React.FC<Props> = ({ onClose, isLoading }) => {
  const dispatch = useAppDispatch();
  const { submitCount, errors, values, dirty, submitForm, setFieldValue } =
    useFormikContext<IBillOfLading>();

  const currentBillOfLadingId = useSelector(selectCurrentBillOfLadingId);
  const contractId = useSelector(selectMovementContractId);
  const movementId = useSelector(selectBillOfLadingMovementId) as string;

  const checkBillOfLadingPermissions = usePermissions(FeatureType.BILL_OF_LADING);

  const { data: branches = [] } = useGetBranchesQuery();
  const { data: yards = [] } = useGetYardsQuery();
  
  const { data: contract } = useGetContractByIdQuery(contractId || "", {
    skip: !contractId,
  });
  
  const { data: client } = useGetClientByIdQuery(contract?.client || "", {
    skip: !contract?.client,
  });

  const { data: movement, isLoading: movementLoading } = useGetMovementByIdQuery(
    { movementId, contractId },
    { skip: !movementId && !contractId }
  );

  const { data: asset } = useGetAssetByIdQuery(
    movement?.asset?._id || "",
    { skip: !movement?.asset?._id }
  );

  const { data: billOfLading } = useGetBOLByIdQuery(
    currentBillOfLadingId || "",
    { skip: !currentBillOfLadingId }
  );

  const branchesHash = useMemo(() => byIds(branches), [branches]);
  const yardsHash = useMemo(() => byIds(yards), [yards]);
  const branch = useMemo(() => getBranch(), [movement, branchesHash]);
  const yard = useMemo(() => asset ? yardsHash[asset.yard] : undefined, [asset, yardsHash]);
  
  const pdfName = useMemo(() => client && contract
    ? `BOL-${billOfLading?.billOfLadingNumber}-${client.legalName}-${contract.projectNumber}.pdf`
    : `BOL-${billOfLading?.billOfLadingNumber}`,
    [billOfLading, client, contract]
  );

  const isFormDisabled = useMemo(() => disableForm(), [currentBillOfLadingId, checkBillOfLadingPermissions]);

  function getBranch() {
    if (movement?.asset?.branch) {
      return branchesHash[movement.asset.branch];
    }
    
    if (movement?.nonAsset) {
      return branchesHash[movement.nonAsset.branch || ""];
    }
    
    if (movement?.accessory) {
      return branchesHash[movement.accessory.branch || ""];
    }
    
    return undefined;
  }


  function getDeclaredValuation() {
    const additionalItemsValue = values.additionalItems.reduce(
      (acc, item) => acc + (item.amount?.amount || 0), 
      0
    );
    const assetArticlesValues = values.assetArticles.reduce(
      (acc, item) => acc + (item.attributes?.amount?.amount || 0), 
      0
    );
    return assetArticlesValues + additionalItemsValue;
  }

  function determinePointOfOrigin() {
    if (!contract) return "";
    if (movement?.type === "OUT") {
      return yard?.address.address || "Address not found";
    }
    
    if (movement?.type === "IN") {
      return displayAddress(contract.shipTo);
    }
    
    return "";
  }

  function pointOfOriginErrorMessage() {
    const recommendedPointOfOrigin = determinePointOfOrigin();
    
    if (movement?.type === "OUT") {
      return `OUT movements should match yard address ${recommendedPointOfOrigin}`;
    }
    
    if (movement?.type === "IN") {
      return `IN movements should match contract Ship To ${recommendedPointOfOrigin}`;
    }
    
    return "";
  }

  function determineConsigneeAddress() {
    if (!contract) return "";

    if (movement?.type === "OUT") {
      return displayAddress(contract.shipTo);
    }
    
    if (movement?.type === "IN") {
      return yard?.address.address || branch?.address.address || "Address not found";
    }
    
    return "";
  }


  function consigneeAddressErrorMessage() {
    const recommendedConsigneeAddress = determineConsigneeAddress();
    
    if (movement?.type === "IN") {
      return `IN movements should match branch address ${recommendedConsigneeAddress}`;
    }
    
    if (movement?.type === "OUT") {
      return `OUT movements should match contract Ship To ${recommendedConsigneeAddress}`;
    }
    
    return "";
  }


  function getDateAndTime(date: Date, time: Date): Date {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const hours = new Date(time).getHours();
    const minutes = new Date(time).getMinutes();
    const seconds = new Date(time).getSeconds();
    const milliseconds = new Date(time).getMilliseconds();

    return new Date(year, month, day, hours, minutes, seconds, milliseconds);
  }

  function disableForm() {
    if (currentBillOfLadingId) {
      return !checkBillOfLadingPermissions(ActionType.UPDATE);
    } else {
      return !checkBillOfLadingPermissions(ActionType.CREATE);
    }
  }

  // Show validation errors after form submission
  useEffect(() => {
    if (submitCount > 0 && Object.keys(errors).length) {
      const formattedErrors = Object.keys(errors).map(error => 
        error
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .replace(/(^|\s)\S/g, (l) => l.toUpperCase())
      );

      dispatch(
        setGlobalMessage({
          messageText: `${formattedErrors.join(" Invalid. ")} Invalid.`,
          severity: "error",
          show: true,
        })
      );
    }
  }, [submitCount, errors, dispatch]);

  // Update declared valuation when additionalItems change
  useEffect(() => {
    setFieldValue("declaredValuation", getDeclaredValuation());
  }, [values.additionalItems, values.assetArticles]);

  const handlePickupTimeChange = (value: Date) => {
    if (!values.scheduledPickupTimeAtOrigin && values.deliveryDate) {
      setFieldValue(
        "scheduledPickupTimeAtOrigin",
        getDateAndTime(new Date(values.deliveryDate), value)
      );
    } else {
      setFieldValue("scheduledPickupTimeAtOrigin", value);
    }
  };

  const handleDeliveryTimeChange = (value: Date) => {
    if (!values.scheduledDeliveryTimeAtDestination && values.deliveryDate) {
      setFieldValue(
        "scheduledDeliveryTimeAtDestination",
        getDateAndTime(new Date(values.deliveryDate), value)
      );
    } else {
      setFieldValue("scheduledDeliveryTimeAtDestination", value);
    }
  };

  return (
    <Form>
      <DialogTitle
        sx={(theme) => ({
          position: "fixed",
          backgroundColor: theme.palette.background.paper,
          zIndex: 10,
          width: "100%",
        })}
      >
        <Box sx={{ textAlign: "right", marginBottom: 2 }}>
          {/* PDF Download Button */}
          {billOfLading && movement ? (
            <PDFDownloadLink
              fileName={pdfName}
              document={
                <BillOfLadingPdf
                  billOfLading={billOfLading}
                  assets={asset ? [asset] : []}
                  branch={branch}
                  movementType={movement?.type}
                />
              }
            >
              <Tooltip title="Bill of Lading">
                <IconButton
                  color="primary"
                  disabled={!Boolean(billOfLading?._id)}
                  sx={{ marginRight: 2 }}
                >
                  <SvgIcon>
                    <PdfIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </PDFDownloadLink>
          ) : (
            <IconButton color="primary" disabled sx={{ marginRight: 2 }}>
              <SvgIcon>
                <PdfIcon />
              </SvgIcon>
            </IconButton>
          )}

          {/* Save Button */}
          <Button
            color="primary"
            size="large"
            variant="outlined"
            className={`save ${dirty ? "unsavedChanges" : ""}`}
            onClick={submitForm}
            disabled={isFormDisabled || isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Save"}
          </Button>

          {/* Close Button */}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
              marginLeft: 2,
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ minWidth: "1600px" }}>
        <Box
          sx={{
            display: "grid",
            columnGap: 1,
            rowGap: 3,
            gridTemplateColumns: "repeat(12, 1fr)",
            overflowY: "auto",
            overflowX: "auto",
            minWidth: "1460px",
            m: 6,
            px: 6,
            pt: 7,
          }}
        >
          {/* Form Title */}
          <Typography variant="h3" sx={{ gridColumn: "1 / 10" }}>
            BILL OF LADING
          </Typography>
          <Box sx={{ gridColumn: "10 / 13" }}>
            <FastTextInput name="billOfLadingNumber" label="Bill Of Lading #" />
          </Box>

          {/* Origin and Date */}
          <Box sx={{ gridColumn: "1 / 10" }}>
            <FastTextInput
              name="pointOfOrigin"
              label="Origin"
              textFieldProps={{
                helperText:
                  values.pointOfOrigin !== determinePointOfOrigin()
                    ? pointOfOriginErrorMessage()
                    : "",
              }}
            />
          </Box>
          <Box sx={{ gridColumn: "10 / 13" }}>
            <FastDateInput name="deliveryDate" label="Date" />
          </Box>

          {/* Consignor Information */}
          <Box sx={{ gridColumn: "1 / 7" }}>
            <FastTextInput name="consignor" label="Consignor" />
          </Box>
          <Box sx={{ gridColumn: "7 / 10" }}>
            <FastTextInput name="consignorContactName" label="Contact Name" />
          </Box>
          <Box sx={{ gridColumn: "10 / 13" }}>
            <FastTextInput name="projectNumber" label="Project Number" />
          </Box>

          <Box sx={{ gridColumn: "1 / 7" }}>
            <FastTextInput name="consignorAddress" label="Consignor Address" />
          </Box>
          <Box sx={{ gridColumn: "7 / 10" }}>
            <FastPhoneInput name="consignorPhone" label="Consignor Phone" />
          </Box>
          <Box sx={{ gridColumn: "10 / 13" }}>
            <FastTextInput
              name="purchaseOrderNumber"
              label="Purchase Order #"
            />
          </Box>

          {/* Scheduling Information */}
          <Box sx={{ gridColumn: "1 / 4" }}>
            <FastDateInput
              name="scheduledPickupTimeAtOrigin"
              label="Scheduled Pickup Date at Origin"
            />
          </Box>
          <Box sx={{ gridColumn: "4 / 6" }}>
            <FastTimeInput
              name="scheduledPickupTimeAtOrigin"
              label="Scheduled Pickup Time at Origin"
              onChange={handlePickupTimeChange}
            />
          </Box>
          <Box sx={{ gridColumn: "6 / 10" }}>
            <FastDateInput
              name="scheduledDeliveryTimeAtDestination"
              label="Scheduled Delivery Date at Destination"
            />
          </Box>
          <Box sx={{ gridColumn: "10 / 13" }}>
            <FastTimeInput
              name="scheduledDeliveryTimeAtDestination"
              label="Scheduled Delivery Time at Destination"
              onChange={handleDeliveryTimeChange}
            />
          </Box>

          {/* Destination and Consignee Information */}
          <Box sx={{ gridColumn: "1 / 13" }}>
            <FastTextInput name="consigneeDestination" label="Destination" />
          </Box>

          <Box sx={{ gridColumn: "1 / 9" }}>
            <FastTextInput name="consigneeName" label="Consignee Name" />
          </Box>
          <Box sx={{ gridColumn: "9 / 13" }}>
            <FastTextInput name="consigneeContactName" label="Contact Name" />
          </Box>

          <Box sx={{ gridColumn: "1 / 9" }}>
            <FastTextInput
              name="consigneeAddress"
              label="Consignee Address"
              textFieldProps={{
                helperText:
                  values.consigneeAddress !== determineConsigneeAddress()
                    ? consigneeAddressErrorMessage()
                    : "",
              }}
            />
          </Box>
          <Box sx={{ gridColumn: "9 / 13" }}>
            <FastTextInput name="consigneeContactPhone" label="Contact Phone" />
          </Box>

          {/* Bill of Lading Articles */}
          <Box sx={{ gridColumn: "1 / 13" }}>
            <BillOfLadingArticles
              assetArticles={values.assetArticles}
              additionalItems={values.additionalItems}
            />
          </Box>

          {/* Special Agreement and Valuation */}
          <Box sx={{ gridColumn: "1 / 10" }}>
            <FastTextInput
              name="specialAgreementBetweenConsignorAndCarrier"
              label="Special Agreement Between Consignor and Carrier"
            />
          </Box>
          <Box sx={{ gridColumn: "10 / 13" }}>
            <CurrencyTextField
              value={{ currency: values.assetArticles[0]?.attributes?.amount?.currency || 'CAD', amount: values.declaredValuation || 0 }}
              onCurrencyChange={() => {}}
              onChange={(e) => setFieldValue("declaredValuation", e.target.value)}
              hideCurrencySelect
              size="small"
              fullWidth
              label="Declared Valuation"
              name="declaredValuation"
            />
          </Box>

          {/* Carrier Information */}
          <Box sx={{ gridColumn: "1 / 10" }}>
            <FastTextInput
              name="carrierCompanyName"
              label="Carrier Company Name"
            />
          </Box>
          <Box sx={{ gridColumn: "10 / 13" }}>
            <FastPhoneInput name="carrierPhone" label="Carrier Phone" />
          </Box>

          {/* Time Information */}
          <Box
            sx={{
              gridColumn: "1 / 7",
              display: "flex",
              alignItems: "center",
              gap: 2,
              mb: 4,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography variant="caption">Time At Origin</Typography>
              <FastTimeInput name="consignorArrivalTime" label="Site Arrival" />
              <FastTimeInput
                name="consignorDepartureTime"
                label="Site Departure"
              />
            </Box>
            <FastHrsMinsInput
              name="consignorLoadingTimeTotalMinutes"
              label="Total Loading Time"
            />
          </Box>
          <Box
            sx={{
              gridColumn: "7 / 13",
              display: "flex",
              alignItems: "center",
              gap: 2,
              mb: 4,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography variant="caption">Time At Destination</Typography>
              <FastTimeInput
                name="consigneeSiteArrivalTime"
                label="Site Arrival Time"
              />
              <FastTimeInput
                name="consigneeSiteDepartureTime"
                label="Site Departure"
              />
            </Box>
            <FastHrsMinsInput
              name="consigneeUnloadingTimeTotalMinutes"
              label="Total Loading Time"
            />
          </Box>

          {/* Additional Time Information */}
          <Box sx={{ gridColumn: "1 / 4" }}>
            <FastHrsMinsInput
              name="consigneeOtherTimeAtOriginOrDestinationTotalMinutes1"
              label="Additional Time At Origin Or Destination (Explain)"
            />
          </Box>
          <Box sx={{ gridColumn: "4 / 13" }}>
            <FastTextInput
              name="consigneeOtherTimeAtOriginOrDestinationExplanation1"
              label="Explanation"
            />
          </Box>
          <Box sx={{ gridColumn: "1 / 4" }}>
            <FastHrsMinsInput
              name="consigneeOtherTimeAtOriginOrDestinationTotalMinutes2"
              label="Additional Time At Origin Or Destination (Explain)"
            />
          </Box>
          <Box sx={{ gridColumn: "4 / 13" }}>
            <FastTextInput
              name="consigneeOtherTimeAtOriginOrDestinationExplanation2"
              label="Explanation"
            />
          </Box>

          {/* Comments */}
          <Box sx={{ gridColumn: "1 / 13" }}>
            <FastTextInput
              name="comments"
              label="Comments"
              textFieldProps={{ multiline: true, minRows: 3 }}
            />
          </Box>

          {/* Acknowledgements */}
          <Box sx={{ gridColumn: "1 / 5" }}>
            <FastTextInput
              name="acknowledgementAtOriginConsignorName"
              label="Acknowledgement at Origin Consignor Name"
            />
          </Box>
          <Box sx={{ gridColumn: "5 / 7" }}>
            <FastDateInput
              name="acknowledgementAtOriginConsignorDate"
              label="Date"
            />
          </Box>
          <Box sx={{ gridColumn: "7 / 11" }}>
            <FastTextInput
              name="acknowledgementAtDestinationConsigneeName"
              label="Acknowledgement at Destination Consignee Name"
            />
          </Box>
          <Box sx={{ gridColumn: "11 / 13" }}>
            <FastDateInput
              name="acknowledgementAtDestinationConsigneeDate"
              label="Date"
            />
          </Box>
          <Box sx={{ gridColumn: "1 / 5" }}>
            <FastTextInput
              name="acknowledgementAtOriginCarrierName"
              label="Acknowledgement at Origin Carrier Name"
            />
          </Box>
          <Box sx={{ gridColumn: "5 / 7" }}>
            <FastDateInput
              name="acknowledgementAtOriginCarrierDate"
              label="Date"
            />
          </Box>
          <Box sx={{ gridColumn: "7 / 11" }}>
            <FastTextInput
              name="acknowledgementAtDestinationCarrierName"
              label="Acknowledgement at Destination Carrier Name"
            />
          </Box>
          <Box sx={{ gridColumn: "11 / 13" }}>
            <FastDateInput
              name="acknowledgementAtDestinationCarrierDate"
              label="Date"
            />
          </Box>
        </Box>
      </DialogContent>
    </Form>
  );
};

export default BillOfLadingForm;