import React from "react";
import { FastField, FastFieldProps } from "formik";
import { Box, Theme, Typography } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import NumberTextField from "components/NumberTextField";

interface Props {
  label: string;
  name: string;
  sx?: SystemStyleObject<Theme>;
}

const FastHrsMinsInput: React.FC<Props> = ({
  label,
  name,
  sx = {},
}) => {
  function convertTotalMinutesToHoursAndMinutes(totalMinutes: number) {
    return {
      hours: Math.floor(totalMinutes / 60),
      minutes:totalMinutes % 60,
    };
  }

  return (
    (<FastField name={name}>
      {({ field, form, meta }: FastFieldProps) => (
        <Box 
          sx={[sx, {
            display: "flex",
            justifyContent: "space-between",
            gap: 3,
            position: "relative",
          }]}
        >
          <Typography variant="caption" sx={{ position: "absolute", top: "-30px"}}>{label}</Typography>
          <NumberTextField
            {...field}
            value={convertTotalMinutesToHoursAndMinutes(field.value).hours}
            label="Hours"
            fullWidth
            error={meta.touched && !!meta.error}
            helperText={meta.error || " "}
            onChange={(e) => {
              form.setFieldValue(
                name,
                Math.abs(
                  Number(
                    convertTotalMinutesToHoursAndMinutes(field.value).minutes
                  ) +
                    Number(e.currentTarget.value) * 60
                )
              );
            }}
            variant="outlined"
            size="small"
          />
          <NumberTextField
            {...field}
            label="Mins"
            fullWidth
            value={convertTotalMinutesToHoursAndMinutes(field.value).minutes}
            error={meta.touched && !!meta.error}
            helperText={meta.error || " "}
            onChange={(e) => {
              const trimmedValue = e.currentTarget.value.replace(/[^0-9]/g, "");
              if (Number(trimmedValue) > 60 || Number.isNaN(Number(trimmedValue))) {
                return;
              }

              form.setFieldValue(
                name,
                Number(
                  convertTotalMinutesToHoursAndMinutes(field.value).hours
                ) *
                  60 +
                  parseInt(trimmedValue || "0")
              );
            }}
            variant="outlined"
            size="small"
          />
        </Box>
      )}
     </FastField>)
  );
};

export default FastHrsMinsInput;
